<template>
    <div class="container-form">
        <template v-if="loaded">
            <div class="form-tbf">
                <div class="input-group profile w-100">
                    <div class="upload-img update" onclick="document.getElementById('avatarFile').click();">
                        <img v-if="urlAvatar != ''" :src="urlAvatar" class="img-circle"/>
                        <img v-else src="../../../../assets/user-circle.svg" class="img-circle"/>
                        <div class="text">
                            <p class="title">{{$t('profile.profile_picture')}}</p>
                            <div class="with-image"><icon-upload /><p>{{$t('profile.upload_new_picture')}}</p></div>
                        </div>
                        <input style="display:none" id="avatarFile" type="file" accept="image/png, image/jpg, image/jpeg, image/svg" v-on:change="onAvatarChange">
                    </div>
                </div>
                
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.first-name') }}*</label>
                        <div v-if="$v.first_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        <div v-if="errorsBe.first_name" class="error-msg">{{ errorsBe.first_name.join(" | ") }}</div>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: $v.first_name.$error}">
                        <div class="icon-left"><icon-people /></div>
                        <div class="icon-right" v-if="first_name != ''" @click.stop="first_name = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.first-name-ph')" class="input-text" v-model="first_name">
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.last-name') }}*</label>	
                        <div v-if="$v.last_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        <div v-if="errorsBe.last_name" class="error-msg">{{ errorsBe.last_name.join(" | ") }}</div>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: $v.last_name.$error}">
                        <div class="icon-left"><icon-people /></div>
                        <div class="icon-right" v-if="last_name != ''" @click.stop="last_name = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.last-name-ph')" class="input-text" v-model="last_name">
                    </div>
                </div>
    
                <div class="input-group" :class="[$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50']" v-if="canChangeDummy">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.dummy_question') }}</label>
                    </div>
                    <div class="input-box bor-0">
                        <div class="checkbox-list">
                            <div class="checkbox-item m-4" @click="dummy_account = 1, email = ''" v-bind:class="{active: dummy_account === 1}">
                                <div class="name">{{ $t('create-user.no') }}</div>
                            </div>
                            <div class="checkbox-item m-4" @click="dummy_account = 0, email = ''" v-bind:class="{active: dummy_account === 0}">
                                <div class="name">{{ $t('create-user.yes') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="input-group" :class="[$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50', {disabled: dummy_account}]">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.email') }}*</label>
                        <div v-if="$v.email.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        <div v-if="errorsBe.email" class="error-msg">{{ errorsBe.email.join(" | ") }}</div>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: $v.email.$error || error_email}">
                        <div class="icon-left"><icon-email /></div>
                        <div class="icon-right" v-if="email != ''" @click.stop="email = ''"><icon-close class="icon-clear" /></div>
                        <input type="email" :placeholder="$t('create-user.email-ph')" class="input-text" v-model="email" @change="error_email = false" :disabled="dummy_account == 1">
                    </div>
                </div>
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.phone') }}</label>
                        <div v-if="errorsBe.phone" class="error-msg">{{ errorsBe.phone.join(" | ") }}</div>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-mobile /></div>
                        <div class="icon-right" v-if="phone != ''" @click.stop="phone = ''"><icon-close class="icon-clear" /></div>
                        <input type="text" :placeholder="$t('create-user.phone-ph')" class="input-text" v-model="phone">
                    </div>
                </div>

                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
                    <div class="label-header">
                        <label class="label">{{ $t('create-user.role') }}</label>
                        <div class="create-new" @click="showModal('role', {from: 'create-user'})">{{ $t('create-user.add-role') }}</div>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-role /></div>
                        <div class="icon-right" v-if="selectedRoles.length" @click.stop="selectedRoles = []"><icon-close class="icon-clear" /></div>
                        <multiselect 
                        class="hide-tags multiselect_checkbox"
                        v-bind:class="{populate: selectedRoles.length}"
                        v-model="selectedRoles"
                        :options="optionsRoles"
                        :allow-empty="true"
                        :show-labels="false"
                        :multiple="true" 
                        :close-on-select="false"
                        track-by="id" 
                        label="name"
                        >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('create-user.choose-role') }}
                                </span>
                            </template>
                            <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
                                <span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="checkbox">
                                    <div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
                                </div>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div class="form-submit">
                <div class="error-msg" v-show="error_message != ''">{{ error_message }}</div>
                <div class="success-msg" v-show="success_message != ''">{{ success_message }}</div>
    
                <div class="actions-steps" v-if="typeCrud == 'edit'">
                    <button class="btn-tbf white only-icon" @click="changeStepFunction('nextStep')">
                        <div class="icon"><icon-arrow /></div>
                    </button>
                </div>
    
                <div class="action-form">
                    <button class="btn-tbf grey" @click="$router.push({name: 'users'})" v-if="typeCrud == 'create'">
                        <span class="text">{{ $t('general.cancel') }}</span>
                    </button>
        
                    <button id="buttonSubmit" class="btn-tbf blue" @click="saveAction()">
                        <div class="loader-spin" v-if="loadedSubmit"></div>
                        <span class="text" v-else>{{ typeCrud == 'create' ? $t('general.add') : $t('general.update') }}</span>
                    </button>
                </div>
            </div>
        </template>
        <LoaderStepRequired v-else />
    </div>
</template>

<script>
import IconPeople from '@/components/Icons/User'
import IconEmail from '@/components/Icons/Email'
import IconMobile from '@/components/Icons/Mobile'
import IconClose from '@/components/Icons/Close'
import IconRole from '@/components/Icons/Role'
import IconUpload from '@/components/Icons/Upload'
import IconArrow from '@/components/Icons/AngleRight'
import LoaderStepRequired from '@/components/PagesLoaders/CreateUserStepRequired'

import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
    components: {
        IconClose,
        IconPeople,
        IconEmail,
        IconMobile,
        IconUpload,
        IconRole,
        IconArrow,
        LoaderStepRequired
    },
    props: {
        typeCrud: String,
        user: Object,
        selectedVacantJob: [Object, String]
    },
    data() {
        return {
            loaded: false,
            loadedSubmit: false,
            canChangeDummy: true,
            urlAvatar: '',
            avatar: '',
            first_name: '',
            last_name: '',
            email: '',
            dummy_account: 0,
            dummyEmail: '',
            phone: '',
            optionsRoles: [],
            selectedRoles: [],
            error_email: false,
            error_message: '',
            success_message: '',
            errorsBe: [],
            initialData: ''
        }
    },
    validations: {
        first_name: {required},
        last_name: {required},
        email: { required: requiredIf( function (model) {
            return this.dummy_account == 0
        })},
    },
    beforeDestroy() {
        this.$root.$off("user_form_change_step");
        this.$root.$off("addDropdownUserCreate");
    },
    watch: {
        user(newUser, oldUser) {
            if(Object.keys(oldUser).length &&  oldUser != newUser){
                this.selectedRoles = [];
                newUser.roles.map((role) => {
                    this.selectedRoles.push(this.optionsRoles.find(el => el.id == role));
                });
            }
        }
    },
    async mounted() {
        await this.getFilters();

        if(this.typeCrud == 'edit') {
            this.populateUserData();
        }

        var checkReadyFilters = setInterval(() => {
            if(this.loaded){
                if(this.$route.query && this.$route.query.vacant_job){
                    this.user.roles.map((roleId) => {
                        if(!this.selectedRoles.find(el => el.id == roleId)) {
                            this.selectedRoles.push(this.optionsRoles.find(el => el.id == roleId));
                        }
                    });
                }
                clearInterval(checkReadyFilters);
            }
        }, 50);

        this.$root.$on('user_form_change_step', (modeSave, changeData) => {
            if(modeSave == 'save') {
                this.saveAction(changeData);
            } else {
                if(!changeData.step) {
                    this.$emit(changeData.direction);
                } else {
                    this.$emit('goToStep', changeData.step)
                }
            }
        });

        this.$root.$on('addDropdownUserCreate', (data, type) => {
            switch (type) {
                case 'role':
                    this.optionsRoles.push(data)
                    this.selectedRoles.push(data)
                    break;
            }
        })
    },
    methods: {
        async getFilters(){
            var filtersDB = {
                roles: true
            }

            await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: filtersDB })
            .then(({data}) => {
                this.optionsRoles = data.data.roles
              
            }).catch(error => {
                if(error.response) {
                    if(error.response.status == 500) {
                        alert(this.$t('error.500'))
                    }
                }
            })
            .finally(() => {
                if(!this.$route.params.slug){
                    setTimeout(() => {
                        this.loaded = true
                        setTimeout(() => {
                            $('.opacity-page').addClass('show')
                        }, 0)
                    }, 0)
                }
            })
        },
        showModal(type, data = false){
            this.$root.$emit('open_modal', type, data);
        },
        populateUserData() {
            this.urlAvatar = this.user.avatar ? this.user.avatar : '';
            this.first_name = this.user.first_name;
            this.last_name = this.user.last_name;
            this.email = this.user.dummy_account ? '' : this.user.email;
            this.phone = this.user.phone ? this.user.phone : '';
            this.dummy_account = this.user.dummy_account;
            this.canChangeDummy = this.dummy_account ? true : false;
            this.dummyEmail = this.user.email;

            this.user.roles.map((roleId) => {
                if(!this.selectedRoles.find(el => el.id == roleId)) {
                    this.selectedRoles.push(this.optionsRoles.find(el => el.id == roleId));
                }
            });

            this.$nextTick(() => {
                this.loaded = true;

                this.initialData = JSON.stringify({
                    urlAvatar: this.urlAvatar,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    phone: this.phone,
                    dummy_account: this.dummy_account,
                    roles: this.user.roles,
                });
            });
        },
        onAvatarChange(e){
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;

            this.urlAvatar = URL.createObjectURL(files[0]);
            this.avatar = files[0]
        },
        saveAction(changeStep = false, withRedirect = false) {
            var btnSubmit = document.getElementById('buttonSubmit');
            btnSubmit.disabled = true;
            this.loadedSubmit = true;

            this.error_message = '';
			this.error_email = false;

            this.$v.$touch()
            if(!this.$v.$invalid){
                var selectedRoles = this.selectedRoles.length ? this.selectedRoles.map(function(a) {return a.id;}) : [];
                let objData = {};
    
                objData.first_name = this.first_name;
                objData.last_name = this.last_name;
                objData.email = this.dummy_account ? this.dummyEmail : this.email;
                objData.dummy_account = this.dummy_account;
                objData.phone = this.phone;
				objData.roles = selectedRoles;

                if(this.$route.query && this.$route.query.vacant_job){
                    objData.workstation_id = this.user.workstation_id,
                    objData.skills = this.user.skills,
                    objData.departments = this.user.departments,
                    objData.foreign_languages = this.user.foreign_languages,
                    objData.work_schedules = this.user.work_schedules
                }
                
                if(this.typeCrud == 'create') { 
                    // From Data Required and stored default value
                    objData.right = 'employee';
                } else {
                    objData.right = this.user.right;
                }

                if(this.typeCrud == 'create'){
                    this.createUser(objData, changeStep, withRedirect)
                }else if(this.typeCrud == 'edit'){
                    this.updateUser(objData, changeStep, withRedirect)
                }

            } else {
                this.loadedSubmit = false;
                btnSubmit.disabled = false;
            }
        },
        createUser(objData, changeStep, withRedirect){
            var btnSubmit = document.getElementById('buttonSubmit');

            axios.post('/users/store', objData)
            .then(({data}) => {
                this.$parent.userData = data.data;
                this.user = data.data;

                this.initialData = JSON.stringify({
                    urlAvatar: this.urlAvatar,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    phone: this.phone,
                    dummy_account: data.data.dummy_account,
                    roles: data.data.roles,
                });
                
                if(this.$route.query && this.$route.query.vacant_job){
                    if(this.user.work_schedules){
                        let objDataOBT = {};

                        objDataOBT.working_days = this.user.work_schedules.schedules.map(el => { return el.day })
                        objDataOBT.promise_time = '10:00'

                        axios.patch(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/people/${data.data.slug}`, objDataOBT)
                    }
                }
                
                if(this.avatar) {
                    this.storeFiles(data.data.id, changeStep, withRedirect)
                } else {
                    this.loadedSubmit = false;
                    btnSubmit.disabled = false;

                    this.success_message = this.$t('create-user.success_create_msg');
                    setTimeout(() => {
                        this.success_message = '';
                    }, 3000);
                    
                    this.$parent.typeCrud = 'edit';
                    this.$router.push({ name: 'user-edit', params: { slug: data.data.slug } });
                    
                    if(changeStep) {
                        this.$nextTick(() => {
                            if(!changeStep.step) {
                                this.$emit(changeStep.direction);
                            } else {
                                this.$emit('goToStep', changeStep.step)
                            }
                        });
                    }

                    if(this.selectedVacantJob) {
                        this.showModal('vacant_job_duration', { vacantJob: this.selectedVacantJob, userSlug: data.data.slug } )
                    }

                    this.populateUserData();

                    if(withRedirect) {
                        if(this.$route.query.department_id){
                            this.$router.push({name: 'company'})
                        }else{
                            this.$router.push({name: 'user-show', params: { slug: data.data.slug }})
                        }
                    }
                }
            }).catch(error => {
                this.errorsBe = error.response.data.errors;
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                setTimeout(()=>{
                    if(error.response.status == 500){
                        this.error_message = this.$t('error.500');
                    } else if(error.response.data.errors.email[0]) {
                        this.error_message = error.response.data.errors.email[0];
                        this.error_email = true;
                    }
                }, 300)
            })
        },
        updateUser(objData, changeStep, withRedirect){
            var btnSubmit = document.getElementById('buttonSubmit');

            axios.patch(`/users/${this.user.slug}`, objData)
            .then(({data}) => {
                this.$parent.userData = data.data;
                this.$router.push({ name: 'user-edit', params: { slug: data.data.slug } });

                this.initialData = JSON.stringify({
                    urlAvatar: this.urlAvatar,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    phone: this.phone,
                    dummy_account: data.data.dummy_account,
                    roles: data.data.roles,
                });

                if(this.avatar) {
                    this.storeFiles(data.data.id, changeStep, withRedirect)
                } else {
                    this.loadedSubmit = false;
                    btnSubmit.disabled = false;

                    this.success_message = this.$t('create-user.success_update_msg');
                    setTimeout(() => {
                        this.success_message = '';
                    }, 3000);

                    if(changeStep) {
                        this.$nextTick(() => {
                            if(!changeStep.step) {
                                this.$emit(changeStep.direction);
                            } else {
                                this.$emit('goToStep', changeStep.step)
                            }
                        });
                    }

                    if(withRedirect) {
                        if(this.selectedVacantJob) {
                            this.showModal('vacant_job_duration', { vacantJob: this.selectedVacantJob, userSlug: data.data.slug } )
                        } else {
                            if(this.$route.query.department_id){
                                this.$router.push({name: 'company'})
                            }else{
                                this.$router.push({name: 'user-show', params: { slug: data.data.slug }})
                            }
                        }
                    }
                }
            })
            .catch(error => {
                this.errorsBe = error.response.data.errors;
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                setTimeout(()=>{
                    if(error.response.status == 500){
                        this.error_message = this.$t('error.500');
                    } else if(error.response.data.errors.email[0]) {
                        this.error_message = error.response.data.errors.email[0];
                        this.error_email = true;
                    }
                }, 300)
            })
        },
        storeFiles(userId, changeStep, withRedirect){
            var btnSubmit = document.getElementById('buttonSubmit');
            var data = new FormData();

            data.append('user_id', userId);
            data.append('avatar', this.avatar);

            axios.post('user-files/store', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
            .then(({data}) => {
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                this.success_message = this.typeCrud == 'create' ? this.$t('create-user.success_create_msg') : this.$t('create-user.success_update_msg');
                setTimeout(() => {
                    this.success_message = '';
                }, 3000);

                if(this.typeCrud == 'edit' && this.user.id == this.$auth.user().id){ this.$auth.fetch() }
                this.$parent.typeCrud = 'edit';

                if(changeStep) {
                    this.$nextTick(() => {
                        if(!changeStep.step) {
                            this.$emit(changeStep.direction);
                        } else {
                            this.$emit('goToStep', changeStep.step)
                        }
                    });
                }

                if(withRedirect) {
                    if(this.selectedVacantJob) {
                        this.showModal('vacant_job_duration', { vacantJob: this.selectedVacantJob, userSlug: data.data.slug } )
                    } else {
                        if(this.$route.query.department_id){
                            this.$router.push({name: 'company'})
                        }else{
                            this.$router.push({name: 'user-show', params: { slug: data.data.slug }})
                        }
                    }
                }
            }).catch(error =>{
                this.loadedSubmit = false;
                btnSubmit.disabled = false;

                setTimeout(()=>{
                    if(error.response.status == 413){
                        this.error_message = this.$t('error.too_large');
                    } else if(error.response.status == 500){
                        this.error_message = this.$t('error.500');
                    } else if(error.response.data.errors.avatar) {
                        this.error_message = error.response.data.errors.avatar[0];
                    }
                }, 300)
            })
        },
        changeStepFunction(direction, stepNo = false) {
            // Check diference between initial data and current
            var currentObject = JSON.stringify({
                urlAvatar: this.urlAvatar,
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                phone: this.phone,
                dummy_account: this.dummy_account,
                roles: this.selectedRoles.length ? this.selectedRoles.map(function(a) {return a.id;}) : [],
            });

            if(this.initialData === currentObject || !this.loaded) { 
                if(direction) { 
                    this.$emit(direction);
                } else if(stepNo) {
                    this.$emit('goToStep', stepNo)
                }
            } else {
                this.$root.$emit('open_modal', 'confirm_change_step', { direction: direction, step: stepNo });
            }
        }
    }
}
</script>